import clsx from "classnames";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { getRecruitmentProcess } from "@/functions/getRecruitmentProcess";
import { useLocale } from "@/hooks/useLocale";
import { DynamicText } from "@/ui/DynamicText";
import classes from "./styles.module.scss";
import { useState } from "react";
import { DynamicHtml } from "@/ui/DynamicHtml";
import { Details } from "@/ui/Details";
import { cleanMarkup } from "@/functions/cleanMarkup";
import Image from "@/ui/Image";

export const RecruitmentProcessSection = ({ className, type, details }) => {
  const locale = useLocale();
  const process = getRecruitmentProcess().filter((i) => i.type.toLowerCase() === type.toLowerCase());
  const [active, setActive] = useState(0);

  return (
    <div className={clsx(className, classes.process)}>
      <Container fluid="xl" className="pt-4 pt-md-5">
        <Stack className="flex-column flex-lg-row">
          <div>
            <DynamicText path={`section.recruitment_process.${type}.title.${locale}`} tag="h3" />
          </div>
          <div>
            <DynamicHtml path={`section.recruitment_process.${type}.text.${locale}`} />
            {details && (
              <Details>
                <DynamicHtml path={`section.recruitment_process.${type}.details.${locale}`} />
              </Details>
            )}
            <Row>
              {process.map((i, k) => (
                <Col
                  key={k}
                  xs={12}
                  className={clsx(classes.card, { active: active === k })}
                  onMouseEnter={() => setActive(k)}
                >
                  <div className={clsx(classes.intro)}>
                    <div className="text-center">
                      <img src={i.icon} alt="" className="mb-3" />
                      <h4 dangerouslySetInnerHTML={{ __html: i[`title_${locale}`] }} />
                    </div>
                  </div>
                  <div className={clsx(classes.details)}>
                    <div className="p-4 p-lg-5 bg-light text-overlay">
                      <i dangerouslySetInnerHTML={{ __html: k + 1 }} />
                      <Image
                        className="text-overlay__background"
                        img={{ path: `section.recruitment_process.${type}.${k}.bg`, src: "/assets/transparent.png" }}
                        size="602x258"
                        alt=""
                      />
                      <div
                        className="ms-4 ms-xl-5 text-overlay__content"
                        dangerouslySetInnerHTML={{ __html: cleanMarkup(i[`text_${locale}`]) }}
                      />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <Row>
              <Col xs={12} className="text-center mt-4 mt-md-0">
                <DynamicHtml path={`section.recruitment_process.${type}.footer.${locale}`} tag="p" />
              </Col>
            </Row>
          </div>
        </Stack>
      </Container>
    </div>
  );
};

RecruitmentProcessSection.defaultProps = {
  details: false,
};
