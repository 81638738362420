import { Col, Container, Row, Stack } from "react-bootstrap";
import { useLocale } from "@/hooks/useLocale";
import { getCommittee } from "@/functions/getCommittee";
import { DynamicText } from "@/ui/DynamicText";
import { DynamicHtml } from "@/ui/DynamicHtml";
import classes from "./styles.module.scss";
import clsx from "classnames";
import Quote from "@/assets/Quote.svg";
import { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { useMediaQuery } from "@react-hook/media-query";
import { Heading } from "@/ui/Heading";
import { team_helper } from "@/helpers/team_helper";
import { useLink } from "@/hooks/useLink";

export const CommitteeSection = ({ className, variant, quoteBlockClasses, pathPrefix }) => {
  const [isMobile, setMobile] = useState(true);
  const md = useMediaQuery("only screen and (min-width: 768px)");
  const [visible, setVisible] = useState(false);
  const locale = useLocale();
  const link = useLink();
  const committee = getCommittee();

  useEffect(() => {
    setMobile(!md);
  }, [md]);

  return (
    <VisibilitySensor
      partialVisibility={true}
      onChange={(v) => {
        if (v) {
          setVisible(true);
        }
      }}
    >
      <div className={clsx(className, classes.committee, `variant-${variant}`, { visible })}>
        <Container>
          {isMobile && (
            <Heading className="mb-4" variant={variant}>
              <DynamicText tag="h2" path={`${pathPrefix}.title.${locale}`} />
            </Heading>
          )}
          <div className="mx-3 mx-md-0">
            <Row>
              <Col xs={12} md={6} className="p-4 p-md-5 bg-light">
                <div className="ps-3 pb-md-5 mb-md-5">
                  {!isMobile && (
                    <DynamicText
                      path={`${pathPrefix}.title.${locale}`}
                      tag="h2"
                      className={clsx(classes.committee__title, "mb-4 h2")}
                    />
                  )}
                  <DynamicHtml
                    path={`${pathPrefix}.text.${locale}`}
                    className={clsx(classes.committee__content, "me-4 mb-md-5 pb-md-4")}
                  />
                </div>
              </Col>
              <Col xs={12} md={6} className={clsx(`p-4 p-md-5`, quoteBlockClasses)}>
                <blockquote className="p-3">
                  <Quote className="mb-4" />
                  <DynamicHtml path={`${pathPrefix}.quote.${locale}`} />
                  <hr className="my-4" />
                  <cite>
                    <DynamicText path={`${pathPrefix}.cite.${locale}`} />
                  </cite>
                </blockquote>
              </Col>
            </Row>
          </div>
          <div className="px-4">
            <Stack className="flex-md-row ms-md-5 ps-md-5" gap={4}>
              {committee.map((i) => {
                const consultant = team_helper.find(i.name, "name");

                return (
                  <div key={i.id} className={classes.committee__card}>
                    <img src={i.photo} className="w-100" alt="" />
                    <Stack className="flex-row justify-content-between align-items-center py-2 px-3">
                      <img src="/assets/diversity/icon.png" alt="" />
                      {consultant ? (
                        <a
                          dangerouslySetInnerHTML={{ __html: i.name }}
                          href={link("team.member", consultant.url_slug)}
                        />
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: i.name }} />
                      )}
                    </Stack>
                  </div>
                );
              })}
            </Stack>
          </div>
        </Container>
      </div>
    </VisibilitySensor>
  );
};

CommitteeSection.defaultProps = {
  className: "py-4 py-md-5",
  variant: "primary",
  quoteBlockClasses: "bg-secondary text-white",
  pathPrefix: "section.committee",
};
