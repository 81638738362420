import { Container, Stack } from "react-bootstrap";
import { useLocale } from "@/hooks/useLocale";
import { getHistory } from "@/functions/getHistory";
import { DynamicText } from "@/ui/DynamicText";
import classes from "./styles.module.scss";
import clsx from "classnames";
import Image from "@/ui/Image";

export const HistorySection = ({ className }) => {
  const locale = useLocale();
  const history = getHistory();

  return (
    <div className={clsx(className, classes.history)}>
      <Container>
        <div className="bg-light text-dark py-0 py-md-5 text-overlay">
          <Stack className="flex-md-row text-overlay__content">
            <div className="py-3 px-3 px-lg-3">
              <DynamicText path={`section.history.title.${locale}`} tag="h3" className="mx-2" />
            </div>
            <div>
              {history.map((i, k) => (
                <Stack key={k} className={clsx(classes.record, "flex-row")}>
                  <div>
                    <time className="px-2 ps-lg-5 pe-lg-3" dangerouslySetInnerHTML={{ __html: i.year }} />
                  </div>
                  <div className="ps-lg-3 pe-4 pe-md-5 pt-2 pb-4">
                    <h4 className="h5" dangerouslySetInnerHTML={{ __html: i[`title_${locale}`] }} />
                    <div dangerouslySetInnerHTML={{ __html: i[`text_${locale}`] }} />
                  </div>
                </Stack>
              ))}
            </div>
          </Stack>
          <Image
            className="text-overlay__background"
            img={{ path: `section.history.bg`, src: "/assets/grid/transparent.png" }}
            size="1300x1931"
            alt=""
          />
        </div>
      </Container>
    </div>
  );
};
