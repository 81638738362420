import { Button, Col, Container, Row } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import { useLocale } from "@/hooks/useLocale";
import { Heading } from "@/ui/Heading";
import { useEffect, useState } from "react";
import UpcomingEventCard from "@/ui/UpcomingEventCard";
import { useLink } from "@/hooks/useLink";

export const EventsSection = ({ className, title, items, sidebar }) => {
  const locale = useLocale();
  const link = useLink();
  const [limit, setLimit] = useState(3);

  useEffect(() => {
    setLimit(sidebar ? 2 : 3);
  }, [sidebar]);

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <Container className="px-2 px-md-3">
        <Row className="mb-4">
          <Col xs={12} md={8}>
            <Heading className="mb-4">
              <DynamicText
                path={title ? `${title.path}.${locale}` : `section.events.title.${locale}`}
                tag="h3"
                className="h2"
              />
            </Heading>
          </Col>
          <Col xs={12} md={4}>
            <Button variant="outline-primary" className="justify-content-start flex-row-reverse" href={link("events")}>
              View all
            </Button>
          </Col>
        </Row>
        <Row>
          {items.slice(0, limit).map((i, k) => (
            <Col key={k} xs={12} md={6} lg={4} className="mt-3 mt-md-0">
              <UpcomingEventCard className="h-100" item={i} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

EventsSection.defaultProps = {
  className: "py-4 py-md-5",
  sidebar: false,
};
