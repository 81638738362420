import { Col, Container, Row, Stack } from "react-bootstrap";
import clsx from "classnames";
import { getExpectations } from "@/functions/getExpectations";
import { Heading } from "@/ui/Heading";
import { DynamicText } from "@/ui/DynamicText";
import { useLocale } from "@/hooks/useLocale";
import classes from "./styles.module.scss";
import { DynamicHtml } from "@/ui/DynamicHtml";

export const ExpectationsSection = ({ className, type, md }) => {
  const locale = useLocale();
  const expectations = getExpectations().filter((i) => i.type.toLowerCase() === type.toLowerCase());

  return (
    <div className={clsx(className, classes.expectations)}>
      <Container>
        <Heading className="mb-4">
          <DynamicText path={`section.expectations.title.${locale}`} tag="h2" />
        </Heading>
        <DynamicHtml path={`section.expectations.${type}.text.${locale}`} className="mb-5" />
        <div className="p-4 p-md-5 bg-secondary text-white">
          <Row>
            {expectations.map((i, k) => (
              <Col key={k} xs={12} sm={6} md={md}>
                <div className={clsx(classes.card, "p-3")}>
                  <figure>
                    <img src={i.icon} alt="" />
                  </figure>
                  <h4 className="h6 fw-normal" dangerouslySetInnerHTML={{ __html: i[`title_${locale}`] }} />
                </div>
              </Col>
            ))}
          </Row>
          <div className={classes.footer}>
            <DynamicHtml path={`section.expectations.${type}.footer.${locale}`} />
          </div>
        </div>
      </Container>
    </div>
  );
};

ExpectationsSection.defaultProps = {
  md: 3,
};
