import { Button, Container, Dropdown, Stack } from "react-bootstrap";
import { placeholders } from "@/data/placeholders";
import { useLocale } from "@/hooks/useLocale";
import { DynamicText } from "@/ui/DynamicText";
import clsx from "classnames";
import classes from "./styles.module.scss";
import { useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import Search from "@/assets/Search.svg";
import VisibilitySensor from "react-visibility-sensor";
import { router } from "@/router";
import PropTypes from "prop-types";
import { specialisms_helper } from "@/helpers/specialisms_helper";
import { useLink } from "@/hooks/useLink";
import { popular_roles_helper } from "@/helpers/popular_roles_helper";
import slug from "slug";

export const JobSearchWidget = ({ className, variant, fullWidth, roles }) => {
  const locale = useLocale();
  const link = useLink();
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [query, setQuery] = useState("");
  const [location, setLocation] = useState("");
  const [radius, setRadius] = useState("");
  const [ready, setReady] = useState(false);

  const buttonLinks = useMemo(() => {
    if (roles === null) {
      return specialisms_helper.fetch({
        parent: null,
        map: (i) => ({ name: i.name, url: link(`jobs.specialism`, i.url_slug) }),
      });
    }

    if (typeof roles === "string") {
      return popular_roles_helper.fetch({
        filter: (i) => {
          if (Array.isArray(i.group)) {
            return (
              i.group.some((g) => slug(g.toLowerCase().trim()) === slug(roles.toLowerCase().trim())) &&
              i.featured === true
            );
          }

          return slug(i.group.toLowerCase().trim()) === slug(roles.toLowerCase().trim()) && i.featured === true;
        },
        map: (i) => ({ name: i.name, url: link(`popular.roles`, i.url_slug) }),
      });
    }

    if (Array.isArray(roles)) {
      return roles.map((i) => ({ name: i.label, url: i.url }));
    }

    return null;
  }, [link, roles]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setReady(true);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = new URLSearchParams({ query, location, radius });

    window.location.href = `${router["jobs"][locale]}?${params.toString()}`;
  };

  return (
    <VisibilitySensor onChange={() => setVisible(true)}>
      <Container className="px-2 px-md-3">
        <div className={clsx(classes.widget, `variant-${variant}`, { fullWidth, visible, loading: !ready })}>
          <Form onSubmit={handleSubmit} className={clsx(className, classes.widget__form)}>
            <Stack className="flex-column flex-sm-row" gap={{ xs: 1, md: 3 }}>
              <Form.Group className="flex-grow-1 border-md-end">
                <Form.Label htmlFor="query" className="mb-0">
                  <DynamicText path={`jobs.search.query.label.${locale}`} />
                </Form.Label>
                <Form.Control
                  value={query}
                  id="query"
                  type="text"
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder={placeholders[`jobs.search.query.placeholder.${locale}`]}
                />
              </Form.Group>
              <Form.Group className="flex-grow-1">
                <Form.Label htmlFor="location" className="mb-0">
                  <DynamicText path={`jobs.search.location.label.${locale}`} />
                </Form.Label>
                <Form.Control
                  value={location}
                  id="location"
                  type="text"
                  onChange={(e) => setLocation(e.target.value)}
                  placeholder={placeholders[`jobs.search.location.placeholder.${locale}`]}
                />
              </Form.Group>
              <Form.Group className="">
                <Form.Label htmlFor="radius" className="mb-0">
                  <DynamicText path={`jobs.search.radius.label.${locale}`} />
                </Form.Label>
                <Dropdown
                  show={show}
                  value={radius}
                  id="radius"
                  drop="down-centered"
                  onMouseOver={() => setShow(true)}
                  onMouseOut={() => setShow(false)}
                >
                  <Dropdown.Toggle>{radius.length > 0 ? radius : "5"} km</Dropdown.Toggle>
                  <Dropdown.Menu flip={false}>
                    <Dropdown.Item onClick={() => setRadius("5")}>5 km</Dropdown.Item>
                    <Dropdown.Item onClick={() => setRadius("10")}>10 km</Dropdown.Item>
                    <Dropdown.Item onClick={() => setRadius("20")}>20 km</Dropdown.Item>
                    <Dropdown.Item onClick={() => setRadius("30")}>30 km</Dropdown.Item>
                    <Dropdown.Item onClick={() => setRadius("40")}>40 km</Dropdown.Item>
                    <Dropdown.Item onClick={() => setRadius("50")}>50 km</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Form.Group>
              <Button
                variant={variant}
                className="px-4 d-flex flex-row justify-content-center align-items-center"
                onClick={handleSubmit}
              >
                <DynamicText path={`jobs.search.button.${locale}`} tag="span" className="me-2" />
                <Search className="flex-shrink-0" />
              </Button>
            </Stack>
          </Form>
          {buttonLinks && buttonLinks.length > 0 && (
            <div className={classes.widget__links}>
              <Stack className="flex-lg-row align-items-center" gap={2}>
                <DynamicText tag="strong" path={`jobs.search.${roles ? "roles" : "specialisms"}.label.${locale}`} />
                <Stack className="flex-row flex-wrap justify-content-center" gap={1}>
                  {buttonLinks.map((i, key) => (
                    <div key={key} className="d-flex align-items-center">
                      <Button variant="secondary btn-sm rounded-5" href={i.url}>
                        <span className="me-2" dangerouslySetInnerHTML={{ __html: i.name }} />
                      </Button>
                    </div>
                  ))}
                </Stack>
              </Stack>
            </div>
          )}
        </div>
      </Container>
    </VisibilitySensor>
  );
};

JobSearchWidget.defaultProps = {
  variant: "primary",
  fullWidth: true,
  roles: null,
};

JobSearchWidget.propTypes = {
  roles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, url: PropTypes.string })),
  ]),
};
