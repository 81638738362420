import { useLocale } from "@/hooks/useLocale";
import { DynamicText } from "@/ui/DynamicText";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { getAwards } from "@/functions/getAwards";
import { Heading } from "@/ui/Heading";
import { DynamicHtml } from "@/ui/DynamicHtml";
import clsx from "classnames";
import classes from "./styles.module.scss";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { Carousel } from "@/ui/Carousel";
import { chunk } from "lodash";

export const AwardsSection = ({ className, variant }) => {
  const lg = useMediaQuery("only screen and (min-width: 992px)");
  const md = useMediaQuery("only screen and (min-width: 768px)");
  const [visibleCount, setVisibleCount] = useState(3);
  const awards = getAwards();
  const locale = useLocale();

  useEffect(() => {
    setVisibleCount(lg ? 3 : md ? 2 : 1);
  }, [lg, md]);

  return (
    <div className={clsx(className, classes.awards, variant)}>
      <Container className="px-2 px-md-3">
        <Heading className="mb-4" variant={variant}>
          <DynamicText path={`section.awards.title.${locale}`} className="h2" tag="h3" />
        </Heading>
        <DynamicHtml path={`section.awards.text.${locale}`} className="pb-3" />
        <Carousel
          indicators={true}
          items={chunk(awards, visibleCount).map((i, k) => (
            <Row key={k}>
              {i.map((award) => (
                <Col key={award.id} xs={12} md={12 / visibleCount}>
                  <div key={k} className="p-1 p-md-3 d-flex flex-column justify-content-around">
                    <figure>
                      <div
                        className="d-flex align-items-end justify-content-center align-items-center pb-md-2"
                        style={{ height: 150 }}
                      >
                        <img src={award.image} alt="" className="mw-100 mh-100" />
                      </div>
                      <figcaption className="d-block text-center fw-semibold">{award[`title_${locale}`]}</figcaption>
                    </figure>
                  </div>
                </Col>
              ))}
            </Row>
          ))}
        />
      </Container>
    </div>
  );
};

AwardsSection.defaultProps = {
  className: "py-4 py-md-5 mb-4 mb-md-5",
  variant: "primary",
};
