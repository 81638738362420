import * as sanitizeHtml from "sanitize-html";

/**
 *
 * @param str
 * @returns {string}
 */
export const stripTags = (str = "") =>
  sanitizeHtml(str, { allowedTags: [] })
    .replace(/\s+/g, " ") // minify
    .replace(/[\u200B-\u200D\uFEFF]/g, "") // remove ZeroWidthSpace
    .trim();
