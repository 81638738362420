import { Collapse } from "react-bootstrap";
import { useState } from "react";
import { useLocale } from "@/hooks/useLocale";
import { DynamicText } from "@/ui/DynamicText";
import classes from "./styles.module.scss";

export const Details = ({ children }) => {
  const locale = useLocale();
  const [open, setOpen] = useState(false);

  return (
    <div className={classes.details}>
      <Collapse in={open} aria-expanded={open}>
        <div>{children}</div>
      </Collapse>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
        className={classes.details__btn}
      >
        <DynamicText path={open ? `details.button.close.${locale}` : `details.button.open.${locale}`} />
      </a>
    </div>
  );
};
