import { useLocale } from "@/hooks/useLocale";
import { Col, Container, Stack } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import { getStatistics } from "@/functions/getStatistics";
import classes from "./styles.module.scss";
import clsx from "classnames";

export const StatisticsSection = ({ className }) => {
  const statistics = getStatistics();
  const locale = useLocale();

  return (
    <div className={clsx(className, classes.statistics)}>
      <Container className="px-2 px-md-3">
        <Stack className="flex-md-row bg-secondary text-white">
          <div className="flex-grow-0 d-flex justify-content-center align-items-center py-3 ps-3 pe-3 pe-md-4 pe-lg-5">
            <DynamicText path={`section.statistics.title.${locale}`} tag="h3" className="mx-0 mx-md-4" />
          </div>
          <div className="flex-grow-1 pb-3 py-md-4">
            <Stack className="flex-row flex-wrap">
              {statistics.map((i, k) => (
                <Col key={k} xs={12} md={6} lg={4} className="d-flex align-items-center justify-content-center">
                  <div className={clsx(classes.card, "py-3 py-md-2 px-4 px-md-3 py-md-5")}>
                    <figure className="me-2 me-md-4">
                      <img src={i.icon} alt="" />
                    </figure>
                    <dl className="ms-3 ms-md-4">
                      <dt>{i[`label_${locale}`]}</dt>
                      <dd>{i[`value_${locale}`]}</dd>
                    </dl>
                  </div>
                </Col>
              ))}
            </Stack>
          </div>
        </Stack>
      </Container>
    </div>
  );
};
