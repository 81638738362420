import { Button, Container, Stack } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import { useLocale } from "@/hooks/useLocale";
import { Heading } from "@/ui/Heading";
import { Carousel } from "@/ui/Carousel";
import { useMediaQuery } from "@react-hook/media-query";
import { useEffect, useState } from "react";
import { JobCard } from "@/ui/JobCard";
import classes from "./styles.module.scss";
import clsx from "classnames";
import { useLink } from "@/hooks/useLink";

export const LatestJobsSection = ({ className, items }) => {
  const lg = useMediaQuery("only screen and (min-width: 992px)");
  const md = useMediaQuery("only screen and (min-width: 768px)");
  const [visibleCount, setVisibleCount] = useState(1);
  const locale = useLocale();
  const link = useLink();

  useEffect(() => {
    setVisibleCount(lg ? 3 : md ? 2 : 1);
  }, [lg, md]);

  return (
    <div className={clsx(className, classes.jobs)}>
      {items?.length ? (
        <Container className="px-2 px-md-3">
          <Stack className="flex-md-row align-items-md-center justify-content-between">
            <Heading>
              <DynamicText path={`section.latest_jobs.title.${locale}`} tag="h2" />
            </Heading>
            <Button variant="outline-primary" href={link("jobs")} className="flex-row-reverse">
              <DynamicText path={`section.latest_jobs.all.${locale}`} />
            </Button>
          </Stack>
          <Carousel
            visibleCount={visibleCount}
            indicators={false}
            controls={true}
            items={items.map((i) => (
              <JobCard className="h-100" key={i.id} {...i} />
            ))}
          />
        </Container>
      ) : (
        ""
      )}
    </div>
  );
};

LatestJobsSection.defaultProps = {
  className: "py-4 py-md-5",
};
