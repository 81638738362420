import { Col, Row, Carousel as BSCarousel } from "react-bootstrap";
import { chunk } from "lodash";
import clsx from "classnames";
import classes from "./styles.module.scss";

/**
 *
 * @param className
 * @param items
 * @param visibleCount
 * @param indicators
 * @param controls
 * @param fade
 * @returns {JSX.Element}
 * @constructor
 */
export const Carousel = ({ className, items, visibleCount, indicators, controls, fade }) => {
  return (
    <div className={clsx(className, classes.carousel)}>
      <BSCarousel
        fade={fade}
        defaultActiveIndex={0}
        indicators={indicators && items.length > visibleCount}
        controls={controls && items.length > visibleCount}
      >
        {chunk(items, visibleCount).map((items, k) => (
          <BSCarousel.Item key={k} className="pt-4 pb-0">
            <Row>
              {items.map((item, k) => (
                <Col xs={Math.ceil(12 / visibleCount)} key={k}>
                  {item}
                </Col>
              ))}
            </Row>
          </BSCarousel.Item>
        ))}
      </BSCarousel>
    </div>
  );
};

Carousel.defaultProps = {
  visibleCount: 1,
  indicators: false,
  controls: false,
  fade: false,
};
